import React, { FC } from "react";

const Tecmo2: FC = () => {
  return (
    <iframe
      id="tecmo2"
      src="https://www.retrogames.cc/embed/22932-tecmo-super-bowl-ii-special-edition-usa.html"
      frameBorder="0"
      height={"100%"}
      allowTransparency={true}
    />
  );
};

export default Tecmo2;
