import { Frame } from "@react95/core";
import React, { FC } from "react";
import vickLogo from "@assets/png/vinu-shield.png";

const AboutModal: FC = () => {
  return (
    <div className="about">
      {/* <div className="title">
        <h3>Address: </h3>
        <Input
          style={{ width: "100%" }}
          placeholder="https://www.vinuerc.com/about.html"
          q
          disabled
        />
      </div> */}

      <div className="body">
        <Frame boxShadow="in" bg="#00346D" className="frame">
          <img src={vickLogo} alt="vinu logo" />
        </Frame>
        <div>
          <div className="subhead">
            <div className="brand">
              <Frame boxShadow="in" bg="#00346D" className="brand-frame">
                <img src={vickLogo} alt="vinu logo" />
              </Frame>
              <h1>
                MICHAEL
                <br />
                <span className="right">VICK</span>
                <br />
                INU
              </h1>
            </div>

            <h2>$VINU</h2>
          </div>
          <p className="main-description">
            Once upon a time, in the world of cryptocurrency, there lived a dog
            named MichaelVickInu. This unique canine had a vision that set him
            apart from the rest of the digital realm. He didn't enter the world
            of crypto just to chase after the next token or ride the waves of
            speculation. MichaelVickInu had a noble purpose in mind – he wanted
            to leave a pawprint of change.
            <br />
            <br />
            His mission was clear: to unite crypto enthusiasts and sports fans
            into a powerful, compassionate community. In this community, values
            like second chances, aspiring to be better, and resilience in the
            face of failure were the guiding principles.
            <br />
            <br />
            The name "MichaelVickInu" was not just a random choice. It was a
            tribute to the legendary NFL player, Michael Vick, whose story of
            falling from grace and rising from the ashes aligned perfectly with
            the community's core values. Michael Vick's journey, marked by
            second chances, a relentless desire to be better, and an unyielding
            spirit in the face of failure, had captured the hearts of many.
            <br />
            <br />
            MichaelVickInu believed that by naming himself after Michael Vick,
            he could remind everyone that life was a game of second chances. He
            aspired to be better each day, just as Michael Vick had done. And he
            celebrated the resilience that allowed him to rise above his past
            mistakes.
            <br />
            <br />
            With his name and mission clear, MichaelVickInu set out to bond
            people around these ideals. He wanted to create a space where
            individuals could come together, no matter their backgrounds or
            interests. It was a project for everyone to embody and watch grow, a
            project that would bridge the gap between fantasy sports and sports
            betting.
            <br />
            <br />
            The community began to flourish. Crypto enthusiasts and sports fans
            came together, drawn by the allure of MichaelVickInu's vision. They
            started to build fantasy sports and sports betting platforms, which
            everyone in the community could use and benefit from simultaneously.
            The platforms were transparent, secure, and user-friendly,
            reflecting the values of the community.
            <br />
            <br />
            As the days went by, MichaelVickInu's project gained traction.
            People from all walks of life joined, and together they embodied the
            spirit of second chances, self-improvement, and resilience. They
            watched their project grow into a thriving ecosystem that catered to
            their passions and beliefs.
            <br />
            <br />
            MichaelVickInu's dream had come true. He had united a diverse group
            of individuals under the banner of change, growth, and resilience.
            Together, they created a lasting legacy that went beyond the world
            of cryptocurrency and sports, leaving a profound pawprint of change
            in their wake.
            <br />
            <br />
            And so, in the world of crypto, MichaelVickInu stood tall as a
            symbol of hope and unity, reminding everyone that it was never too
            late for a second chance, a chance to aspire to be better, and a
            chance to bounce back from failure stronger than ever before.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;
