import styled from "styled-components";

export const AppSC = styled.div`
  .about-modal {
    width: 800px;
    z-index: 100;

    @media only screen and (max-width: 1024px) {
      width: 90%;
    }
  }
  .about {
    padding: 0.5rem 0.25rem;

    .main-description {
      max-height: 400px;
      overflow-y: scroll;

      @media only screen and (max-width: 1024px) {
        max-height: 250px;
      }
    }
    .title {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      h1 {
        font-family: "Times New Roman";
        font-size: 2rem;
        span {
          font-family: "NFL";
        }
      }
      h3 {
        margin: 0px;
      }

      input {
        ::placeholder {
          color: black;
        }
      }
    }

    .body {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 1.5rem;
      align-items: start;

      .subhead {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

        .brand-frame {
          display: none;
        }
        h1 {
          font-family: "NFL";
          font-size: 3rem;
          letter-spacing: 0.2em;
          margin-top: 0px;
          margin-bottom: 0px;

          span {
            font-family: "NFL";
            margin-left: 1rem;
          }
        }

        h2 {
          font-family: "NFL";
          font-size: 3rem;
          color: #e90000;
        }
      }

      .frame {
        display: flex;
        padding: 12px;
        height: 100%;
        align-items: center;

        img {
          max-width: 100%;
        }
      }
    }

    .images {
      display: flex;
    }
    img {
      max-width: 300px;
    }
    p {
      font-size: 1.25rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    .about {
      .body {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px;

        p {
          font-size: 0.8rem;
          width: 100%;
        }

        .subhead {
          .brand {
            display: flex;
            gap: 1rem;
            align-items: center;

            .brand-frame {
              display: block;
              height: 100%;
              img {
                max-width: 80px;
                padding: 6px;
              }
            }
          }
          h1 {
            font-size: 1.5rem;
          }

          h2 {
            font-size: 1rem;
          }
        }

        .frame {
          display: none;
        }
      }
    }
  }
`;
