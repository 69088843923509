import React, { FC } from "react";

const Tecmo: FC = () => {
  return (
    <iframe
      id="tecmo"
      src="https://www.retrogames.cc/embed/20215-tecmo-super-bowl-usa.html"
      frameBorder="0"
      height={"100%"}
      allowTransparency={true}
    />
  );
};

export default Tecmo;
