import { Alert } from "@react95/core";
import React, { FC } from "react";

const isMobile = window.innerWidth < 768;

const Madden04: FC = () => {
  const [alertShown, setAlertShown] = React.useState(true);
  return (
    <div className="madden-04">
      <iframe
        id="madden04"
        src="https://www.retrogames.cc/embed/29088-madden-2004-u-mode7.html"
        frameBorder="0"
        height="100%"
        width="100%"
        allowTransparency={true}
      />
      {alertShown && (
        <Alert
          title="Instructions to run Madden 2004"
          className="madden-04-alert"
          style={{ zIndex: 5, top: 0 }}
          message={
            isMobile
              ? "Press 'Start' when Streetfighter intro shows"
              : "Press enter when you see the Streetfighter intro"
          }
          closeAlert={() => {
            setAlertShown(false);
          }}
          buttons={[
            {
              value: "Ok",
              onClick: () => {
                setAlertShown(false);
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default Madden04;
