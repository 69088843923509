import React, { FC } from "react";

const Madden05: FC = () => {
  return (
    <iframe
      id="madden05"
      src="https://www.retrogames.cc/embed/27940-madden-nfl-2005-u-venom.html"
      frameBorder="0"
      height={"100%"}
      allowTransparency={true}
    />
  );
};

export default Madden05;
