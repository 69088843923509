import React, { FC, useEffect } from "react";
import { DesktopIconSC, DesktopSC } from "./Desktop.styled";
import AppButton from "../AppButton";
import { Mspaint, Notepad2, Quartz100 } from "@react95/icons";
import uniswapImage from "@assets/png/uniswap.png";
import traderjoeImage from "@assets/png/traderjoe.png";
import dextoolsImage from "@assets/png/dextools.png";
import telegramImage from "@assets/png/telegram.png";
import twitterImage from "@assets/png/twitter.png";
import zealyImage from "@assets/png/zealy.png";
import mainLogo from "@assets/png/logo.png";
import Bonk from "@components/modals/Bonk";
import Paint from "@components/modals/Paint";
import Tecmo from "@components/modals/Tecmo";
import AboutModal from "@components/modals/AboutModal";
import Tecmo2 from "@components/modals/Tecmo2";
import tecmoImage from "@assets/png/games/tecmo.jpeg";
import tecmo2Image from "@assets/png/games/tecmose.png";
import madden05Image from "@assets/png/games/madden05.jpeg";
import madden64Image from "@assets/png/games/madden64.jpeg";
import madden04Image from "@assets/png/games/madden04.jpeg";
import donateDoggo from "@assets/jpg/donate.jpg";
import sarahImg from "@assets/jpg/sarah.jpg";
import tecmoGameboy from "@assets/jpg/tecmo-gb.jpeg";
import Madden64 from "@components/modals/Madden64";
import Madden04 from "@components/modals/Madden04";
import { ReactComponent as SvgLogo } from "@assets/svg/vinu-logo.svg";
import vinuShieldImg from "@assets/png/vinu-shield.png";
import { Video } from "@react95/core";
import highlightReel from "@assets/mp4/vick-plays.mp4";
import sarahVideo from "@assets/mp4/sarah-video.mp4";
import TecmoGameboy from "@components/modals/TecmoGameboy";
import Madden05 from "@components/modals/Madden05";

const isMobile = window.innerWidth < 768;

const HighlightsVideo = () => {
  useEffect(() => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.setAttribute("autoPlay", "true");
      videoElement.play();
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <video playsInline autoPlay id="highlights-m" style={{ width: 320 }}>
          <source src={highlightReel} type="video/mp4" />
        </video>
      ) : (
        <Video w="320" src={highlightReel} />
      )}
    </>
  );
};

const SarahVideo = () => {
  useEffect(() => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.setAttribute("autoPlay", "true");
      videoElement.play();
    }
  }, []);

  return (
    <div className="video sarah">
      {isMobile ? (
        <video playsInline autoPlay id="sarah-m" style={{ width: 320 }}>
          <source src={sarahVideo} type="video/mp4" />
        </video>
      ) : (
        <Video w="320" src={sarahVideo} />
      )}
    </div>
  );
};

export const desktopItems = [
  {
    title: "About $VINU",
    icon: <DesktopIconSC src={mainLogo} />,
    shown: false,
    url: undefined,
    children: <AboutModal />,

    width: isMobile ? 350 : 800,
    show: isMobile ? true : true,
  },
  {
    title: "highlights.mp4",
    icon: <Quartz100 />,
    shown: false,

    url: undefined,

    children: (
      <div className="video">
        <HighlightsVideo />
      </div>
    ),
    width: isMobile ? 324 : 324,
    show: isMobile ? true : true,
  },
  {
    title: "Buy $VINU",
    icon: <DesktopIconSC src={traderjoeImage} />,
    shown: false,
    url: "https://traderjoexyz.com/avalanche/trade?outputCurrency=0x3Bc9cD193F49656c6A89fC1DA53c6be35EF21f45",

    height: isMobile ? 400 : 650,
    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "Dexscreener",
    icon: <DesktopIconSC src={dextoolsImage} />,
    shown: false,
    url: "https://dexscreener.com/avalanche/0x3Bc9cD193F49656c6A89fC1DA53c6be35EF21f45",

    height: isMobile ? 400 : 650,
    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "Telegram",
    icon: <DesktopIconSC src={telegramImage} />,
    shown: false,

    url: "https://t.me/MichaelVickInu",
    height: isMobile ? 400 : 650,
    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "Zealy",
    icon: <DesktopIconSC src={zealyImage} />,
    shown: false,

    url: "https://zealy.io/cw/memeairdrops/leaderboard/815af76d-b8f1-403e-9c44-eaeff4f231f8",
    height: isMobile ? 400 : 650,
    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "Playbook",
    icon: <Notepad2 />,
    shown: false,

    url: "https://docs.google.com/document/d/1siqFkK6BNLsNa5_c35DP6sQ4IAPOthdBT0CHU44Vu_4/edit?usp=sharing",
    height: isMobile ? 400 : 650,
    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "sad_doggo.mp4",
    icon: <DesktopIconSC src={sarahImg} />,
    shown: isMobile ? false : false,
    url: undefined,
    children: <SarahVideo />,
    width: isMobile ? 324 : 324,
    show: isMobile ? true : true,
  },

  {
    title: "Stop Dog Fighting",
    icon: <DesktopIconSC src={donateDoggo} />,
    shown: false,

    url: "https://standupforpits.us/dog-fighting/",
    height: isMobile ? 400 : 650,
    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "bonk.gif",
    icon: (
      <DesktopIconSC
        src={"https://media.tenor.com/oHjfWJorYB8AAAAC/bonk.gif"}
      />
    ),
    shown: false,

    url: undefined,
    children: <Bonk />,

    width: isMobile ? 300 : 650,
    show: isMobile ? true : true,
  },
  {
    title: "TECMO Superbowl",
    icon: <img src={tecmoImage} alt="TECMO" />,
    shown: false,
    url: undefined,

    children: <Tecmo />,
    height: isMobile ? 300 : 500,
    width: isMobile ? 350 : 800,
    show: isMobile ? false : true,
  },
  {
    title: "TECMO Superbowl 2",
    icon: <img src={tecmo2Image} alt="TECMO2" />,
    shown: false,
    url: undefined,

    children: <Tecmo2 />,
    height: isMobile ? 300 : 600,
    width: isMobile ? 350 : 900,
    show: isMobile ? false : true,
  },

  {
    title: "Madden N64",
    icon: <img src={madden64Image} alt="madden64" />,
    shown: false,
    url: undefined,

    children: <Madden64 />,
    height: isMobile ? 300 : 600,
    width: isMobile ? 350 : 900,
    show: isMobile ? false : true,
  },
  {
    title: "Madden '04",
    icon: <img src={madden04Image} alt="madden04" />,
    shown: false,
    url: undefined,
    children: <Madden04 />,
    height: isMobile ? 500 : 600,
    width: isMobile ? 350 : 700,
    show: isMobile ? true : true,
  },
  {
    title: "Madden '05",
    icon: <img src={madden05Image} alt="madden98" />,
    shown: false,
    url: undefined,

    children: <Madden05 />,
    height: isMobile ? 500 : 600,
    width: isMobile ? 350 : 900,
    show: isMobile ? true : true,
  },
  {
    title: "Tecmo Bowl",
    icon: <img src={tecmoGameboy} alt="tecmoBowl" />,
    shown: false,
    url: undefined,

    children: <TecmoGameboy />,
    height: isMobile ? 500 : 550,
    width: isMobile ? 350 : 700,
    show: isMobile ? true : false,
  },
  {
    title: "MS Paint",
    icon: <Mspaint />,
    shown: false,
    url: undefined,

    height: isMobile ? 400 : 600,
    width: isMobile ? 350 : 800,
    show: isMobile ? true : true,
    children: <Paint />,
  },
];

const Desktop: FC = () => {
  useEffect(() => {
    const videoElement = document.getElementById("sarah-m") as HTMLVideoElement;
    if (videoElement) {
      videoElement.play();
    }
  }, []);

  return (
    <DesktopSC>
      <div className="desktop-icons">
        {desktopItems.map((item, index) => (
          <>
            {item.show && (
              <AppButton
                icon={item.icon}
                title={item.title}
                key={index}
                shown={item.shown}
                url={item.url}
                children={item.children}
                width={item.width}
                height={item.height}
              />
            )}
          </>
        ))}
      </div>
      <a href="https://traderjoexyz.com/avalanche/trade?outputCurrency=0x3Bc9cD193F49656c6A89fC1DA53c6be35EF21f45" className="ticker" target="_blank" rel="noopener noreferrer">
        <img src={vinuShieldImg} className="logo" />
        <h1 className="buy-vinu">BUY $VINU</h1>
      </a>
    </DesktopSC>
  );
};

export default Desktop;
