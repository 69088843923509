import { Button, List, Modal } from "@react95/core";
import { Computer } from "@react95/icons";
import React, { FC } from "react";
import { AppButtonSC } from "./AppButton.styled";
import AppLink from "../AppLink";

interface AppButtonProps {
  icon: any;
  title: string;
  shown: boolean;
  url: string | undefined;
  children?: React.ReactNode;
  height: any;
  width: any;
}

const AppButton: FC<AppButtonProps> = ({
  icon,
  title,
  shown,
  url,
  children,
  height,
  width,
}) => {
  const [showModal, toggleShowModal] = React.useState(shown);
  const handleOpenModal = () => toggleShowModal(true);
  const handleCloseModal = () => toggleShowModal(false);

  if (url) {
    return <AppLink icon={icon} title={title} url={url} />;
  }

  return (
    <>
      <AppButtonSC onClick={handleOpenModal}>
        {icon}
        <p className="text">{title}</p>
      </AppButtonSC>
      {showModal && (
        <>
          {title === "About $VINU" ? (
            <Modal
              className={"modal about-modal"}
              width={width}
              height={height}
              icon={icon}
              title={title}
              closeModal={handleCloseModal}
              children={children}
              buttons={[
                {
                  value: "Buy",
                  onClick: () => window.open("https://traderjoexyz.com/avalanche/trade?outputCurrency=0x3Bc9cD193F49656c6A89fC1DA53c6be35EF21f45", "_blank"),
                },
                {
                  value: "Close",
                  onClick: handleCloseModal,
                },
              ]}
              menu={[
                {
                  name: "Socials",
                  list: (
                    <List>
                      <List.Item
                        onClick={() =>
                          window.open(
                            "https://twitter.com/MichaelVickInu",
                            "_blank"
                          )
                        }
                      >
                        Twitter
                      </List.Item>
                      <List.Item
                        onClick={() =>
                          window.open("https://t.me/MichaelVickInu", "_blank")
                        }
                      >
                        Telegram
                      </List.Item>
                    </List>
                  ),
                },
                {
                  name: "Utilities",
                  list: (
                    <List>
                      <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        Uniswap
                      </List.Item>
                      <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        Dextools
                      </List.Item>
                      {/* <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        CoinMarketCap
                      </List.Item>
                      <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        CoinGecko
                      </List.Item> */}
                    </List>
                  ),
                },
              ]}
            />
          ) : (
            <Modal
              className={
                title === "highlights.mp4" || title === "sad_doggo.mp4"
                  ? "video-modal modal"
                  : "modal"
              }
              width={width}
              height={height}
              icon={icon}
              title={title}
              closeModal={handleCloseModal}
              children={children}
              menu={[
                {
                  name: "Socials",
                  list: (
                    <List>
                      <List.Item
                        onClick={() =>
                          window.open(
                            "https://twitter.com/MichaelVickInu",
                            "_blank"
                          )
                        }
                      >
                        Twitter
                      </List.Item>
                      <List.Item
                        onClick={() =>
                          window.open("https://t.me/MichaelVickInu", "_blank")
                        }
                      >
                        Telegram
                      </List.Item>
                    </List>
                  ),
                },
                {
                  name: "Utilities",
                  list: (
                    <List>
                      <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        Uniswap
                      </List.Item>
                      <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        Dextools
                      </List.Item>
                      {/* <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        CoinMarketCap
                      </List.Item>
                      <List.Item
                        onClick={() =>
                          window.open("https://uniswap.org", "_blank")
                        }
                      >
                        CoinGecko
                      </List.Item> */}
                    </List>
                  ),
                },
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default AppButton;
