import styled from "styled-components";
import bgImg from "@assets/jpg/background.jpeg";
import bgMobile from "@assets/jpg/background-mobile.jpg";

export const DesktopSC = styled.div`
  padding: 2rem;
  background: black;
  overflow: hidden;
  height: 100%;
  background-image: url(${bgImg});
  background-size: cover;
  position: relative;

  .ticker {
    max-width: 200px;
    position: absolute;
    bottom: 50px;
    right: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    z-index: 1;

    .logo {
      width: 100px;
    }
    @media only screen and (max-width: 1024px) {
      display: none;
    }

    .ticker-text {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    background-image: url(${bgMobile});
    background-position: bottom;
  }

  .desktop-icons {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    max-width: 200px;
    z-index: 3;
    position: relative;

    @media only screen and (max-width: 1024px) {
      gap: 0.75rem;
    }
  }

  .readme {
    div {
      padding: 24px 24px;
    }
    p {
      font-size: 1.25rem;
    }

    @media only screen and (max-width: 1024px) {
      div {
        padding: 12px 12px;
      }
    }
  }

  .about {
    padding: 0.5rem 0.25rem;

    .main-description {
      max-height: 400px;
      overflow-y: scroll;

      @media only screen and (max-width: 1024px) {
        max-height: 250px;
      }
    }
    .title {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      h1 {
        font-family: "Times New Roman";
        font-size: 2rem;
        span {
          font-family: "NFL";
        }
      }
      h3 {
        margin: 0px;
      }

      input {
        ::placeholder {
          color: black;
        }
      }
    }

    .body {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 1.5rem;
      align-items: start;

      .subhead {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

        .brand-frame {
          display: none;
        }
        h1 {
          font-family: "NFL";
          font-size: 3rem;
          letter-spacing: 0.2em;
          margin-top: 0px;
          margin-bottom: 0px;

          span {
            font-family: "NFL";
            margin-left: 1rem;
          }
        }

        h2 {
          font-family: "NFL";
          font-size: 3rem;
          color: #e90000;
        }
      }

      .frame {
        display: flex;
        padding: 12px;
        height: 100%;
        align-items: center;

        img {
          max-width: 100%;
        }
      }
    }

    .images {
      display: flex;
    }
    img {
      max-width: 300px;
    }
    p {
      font-size: 1.25rem;
    }
  }

  a {
    text-decoration: none;
  }

  .buy-vinu {
    font-family: "NFL";
    color: white;
  }

  @media only screen and (max-width: 1024px) {
    .about {
      .body {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0px;

        p {
          font-size: 0.8rem;
          width: 100%;
        }

        .subhead {
          .brand {
            display: flex;
            gap: 1rem;
            align-items: center;

            .brand-frame {
              display: block;
              height: 100%;
              img {
                max-width: 80px;
                padding: 6px;
              }
            }
          }
          h1 {
            font-size: 1.5rem;
          }

          h2 {
            font-size: 1rem;
          }
        }

        .frame {
          display: none;
        }
      }
    }
  }
`;

export const DesktopIconSC = styled.img``;
