import { Frame } from "@react95/core";
import React, { FC } from "react";

const Bonk: FC = () => {
  return (
    <img
      style={{ width: "100%", height: "auto" }}
      src="https://media.tenor.com/oHjfWJorYB8AAAAC/bonk.gif"
      alt="bonk"
    />
  );
};

export default Bonk;
