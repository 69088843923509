import React, { FC } from "react";

const Madden64: FC = () => {
  return (
    <iframe
      id="madden64"
      src="https://www.retrogames.cc/embed/32788-madden-football-64-usa.html"
      frameBorder="0"
      height={"100%"}
      allowTransparency={true}
    />
  );
};

export default Madden64;
