import React, { FC } from "react";

const TecmoGameboy: FC = () => {
  return (
    <iframe
      id="tecmoGameboy"
      src="https://www.retrogames.cc/embed/26000-tecmo-bowl-usa.html"
      frameBorder="0"
      height={"100%"}
      allowTransparency={true}
    />
  );
};

export default TecmoGameboy;
