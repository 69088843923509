import React from "react";
import { Alert, List, Modal } from "@react95/core";
import Layout from "./components/Layout";
import mainLogo from "@assets/png/logo.png";
import nflSfx from "./assets/mp3/nfl.mp3";
import AboutModal from "@components/modals/AboutModal";
import { DesktopIconSC } from "@components/Layout/components/Desktop/Desktop.styled";
import { AppSC } from "./App.styled";
const nflSound = new Audio(nflSfx);
nflSound.addEventListener("timeupdate", function () {
  if (this.currentTime > 20.5) {
    this.pause();
  }
});

const isMobile = window.innerWidth < 768;

const aboutDetails = {
  title: "About $VINU",
  icon: <DesktopIconSC src={mainLogo} />,
  shown: false,
  url: undefined,
  children: <AboutModal />,

  show: isMobile ? true : true,
};

function App() {
  const [fakeGayShown, toggleFakeGayShown] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = () => setShowModal(false);

  return (
    <AppSC className="app">
      {showModal && (
        <>
          <Modal
            className={"modal about-modal"}
            width={isMobile ? "350px" : "800px"}
            height={isMobile ? "500px" : "600px"}
            icon={aboutDetails.icon}
            title={aboutDetails.title}
            closeModal={handleCloseModal}
            children={aboutDetails.children}
            buttons={[
              {
                value: "Buy",
                onClick: () => window.open("https://traderjoexyz.com/avalanche/trade?outputCurrency=0x3Bc9cD193F49656c6A89fC1DA53c6be35EF21f45", "_blank"),
              },
              {
                value: "Close",
                onClick: handleCloseModal,
              },
            ]}
            menu={[
              {
                name: "Socials",
                list: (
                  <List>
                    <List.Item
                      onClick={() =>
                        window.open(
                          "https://twitter.com/MichaelVickInu",
                          "_blank"
                        )
                      }
                    >
                      Twitter
                    </List.Item>
                    <List.Item
                      onClick={() =>
                        window.open("https://t.me/MichaelVickInu", "_blank")
                      }
                    >
                      Telegram
                    </List.Item>
                  </List>
                ),
              },
              {
                name: "Utilities",
                list: (
                  <List>
                    <List.Item
                      onClick={() =>
                        window.open("https://traderjoexyz.com/avalanche/", "_blank")
                      }
                    >
                      Uniswap
                    </List.Item>
                    <List.Item
                      onClick={() =>
                        window.open("https://dexscreener.com", "_blank")
                      }
                    >
                      Dexscreener
                    </List.Item>
                  </List>
                ),
              },
            ]}
          />
        </>
      )}

      {fakeGayShown && (
        <Alert
          title="ITS GAME DAY BITCH"
          style={{ zIndex: 5 }}
          message="Will MichaelVickInu become the intersection for sports and crypto?"
          closeAlert={async () => {
            await nflSound.play();
            toggleFakeGayShown(false);
          }}
          buttons={[
            {
              value: "YES",
              onClick: async () => {
                await nflSound.play();
                toggleFakeGayShown(false);
                setShowModal(true);
              },
            },
            {
              value: "No",
              onClick: async () => {
                window.open(
                  "https://resources.specialolympics.org/governance/registration-forms/athlete-registration-forms",
                  "_blank"
                );
              },
            },
          ]}
        />
      )}
      <Layout />
    </AppSC>
  );
}

export default App;
